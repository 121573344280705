import {defineStore} from "pinia"

export const useVisibleStateFilterStore = defineStore('visible_state_filter', {
    state: () => ({
        isVisible: false
    }),

    getters:{
        getVisible: (state) => state.isVisible
    },

    actions:{
        setVisible(boolean){
            this.isVisible = boolean
        }
    },

    persist: true,
})
